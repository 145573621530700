import React from 'react';
import PropTypes from 'prop-types';
import { Box, Input, MenuItem, Select as MuiSelect } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const SimpleSelect = ({ items, value, onChange, ...props }) => {
  const classes = useStyles();

  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <MuiSelect
      classes={{ root: classes.root }}
      IconComponent={(propsData) => (
        <Box {...propsData} mt={-0.25} mr={1}>
          <FontAwesomeIcon icon={faChevronDown} />
        </Box>
      )}
      input={<Input disableUnderline classes={{ root: classes.inputRoot }} />}
      value={value}
      onChange={handleChange}
      {...props}
    >
      {items.map(({ name, value }) => (
        <MenuItem value={value} key={value}>
          {name}
        </MenuItem>
      ))}
    </MuiSelect>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(1),
    borderRadius: 8,
    overflow: 'hidden',
    width: 100,
    backgroundColor: `${theme.palette.common.white} !important`,
  },
  inputRoot: {
    borderRadius: 8,
    overflow: 'hidden',
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));

SimpleSelect.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string, value: PropTypes.string }),
  ),
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

SimpleSelect.defaultProps = {
  items: [],
  value: '',
};

export default SimpleSelect;
