/* eslint-disable react/jsx-props-no-spreading */
import React, { FC } from 'react';
import { Slider as MuiSlider } from '@mui/material';
import { ISlider } from './slider.interface';
import { useSliderStyles } from './slider.styles';

export const Slider: FC<ISlider> = ({
  min,
  max,
  step,
  value,
  onChange,
  LabelFormatComponent,
  sx,
  ...props
}) => {
  const classes = useSliderStyles();
  const handleChange = (_, newValue: number | number[]) => {
    onChange(newValue);
  };

  return (
    <MuiSlider
      classes={{
        root: classes.root,
        track: classes.track,
        rail: classes.rail,
        thumb: classes.thumb,
        valueLabel: classes.valueLabel,
        active: classes.active,
      }}
      step={step}
      min={min}
      max={max}
      sx={sx}
      value={Array.isArray(value) ? value[0] : value}
      onChange={handleChange}
      valueLabelFormat={LabelFormatComponent}
      valueLabelDisplay="on"
      {...props}
    />
  );
};

Slider.defaultProps = {
  step: 100,
  min: 0,
  max: 100000,
  value: 0,
  LabelFormatComponent: (value) => value,
};
