import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';

export const ButtonIcon = ({ children, ...props }) => {
  const classes = useStyles();

  return (
    <IconButton classes={{ root: classes.root }} {...props}>
      {children}
    </IconButton>
  );
};

ButtonIcon.propTypes = {
  children: PropTypes.element.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: 32,
    height: 32,
    borderRadius: '50%',
    backgroundColor: theme.palette.common.white,
  },
}));

export default ButtonIcon;
