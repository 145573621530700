import { makeStyles } from '@mui/styles';

const THUMB_WIDTH = 80;

export const useSliderStyles = makeStyles((theme: any) => ({
  root: {
    margin: '0 auto',
    width: `100%`,
  },
  active: {},
  track: {
    height: 4,
    color: theme.palette.darkGrey.main,
    borderRadius: 2,
  },
  rail: {
    color: theme.palette.darkGrey.main,
    opacity: 1,
    height: 4,
    borderRadius: 2,
  },
  thumb: {
    height: 24,
    width: THUMB_WIDTH,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 12,
    zIndex: 10,
    boxShadow: '#ebebeb 0 2px 2px',
    '&:focus, &:hover, &$active': {
      boxShadow: '#ccc 0 2px 3px 1px',
    },
  },
  valueLabel: {
    top: 80,
    left: -48,
    backgroundColor: theme.palette.common.black,
    width: 180,
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderRadius: 5,
    // Remove default inner content
    '& *': {
      background: 'transparent',
      height: 'auto',
    },
    // Tooltip triangle
    '&::before': {
      content: "''",
      position: 'absolute',
      top: -6,
      borderStyle: 'solid',
      borderWidth: ' 0 6px 7px 6px',
      borderColor: `transparent transparent ${theme.palette.common.black} transparent`,
    },
    // "||" symbol on thumb
    '&::after': {
      content: "'||'",
      position: 'absolute',
      top: -28,
      fontWeight: 400,
      fontSize: 16,
      color: theme.palette.secondary.main,
    },
  },
}));
