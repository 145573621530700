import { makeStyles } from '@mui/styles';

export const sliderWithPanelStyles = {
  container: {
    '&.MuiSlider-root': {
      display: 'block',
    },
    '& .MuiSlider-valueLabel': {
      top: 62,
    },
  },
};

export const useSliderWithPanelStyles = makeStyles((theme: any) => ({
  panel: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(6),
  },
  item: {
    color: theme.palette.darkGrey.main,
    cursor: 'pointer',
    fontSize: '0.75rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1rem',
    },
  },
  selectedItem: {
    color: theme.palette.info.main,
  },
}));
