/* eslint-disable react/jsx-props-no-spreading */
import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import classnames from 'classnames';
import { Slider } from '../Slider';
import { ISliderWithPanel } from './slider-with-panel.interface';
import {
  sliderWithPanelStyles,
  useSliderWithPanelStyles,
} from './slider-with-panel.styles';

export const SliderWithPanel: FC<ISliderWithPanel> = ({
  levels,
  value,
  onChange,
  ...sliderProps
}) => {
  const classes = useSliderWithPanelStyles();

  const handleLevelSelect = (index: number) => {
    onChange(index);
  };

  return (
    <>
      <Box
        sx={{
          px: '20px',
        }}
      >
        <Slider
          value={value}
          onChange={onChange}
          {...sliderProps}
          sx={sliderWithPanelStyles.container}
        />
      </Box>

      <Box className={classes.panel}>
        {levels.map(({ id, name }, index) => (
          <Typography
            key={id}
            variant="h4"
            className={classnames(
              classes.item,
              value === index && classes.selectedItem,
            )}
            onClick={() => handleLevelSelect(index)}
          >
            {name}
          </Typography>
        ))}
      </Box>
    </>
  );
};

SliderWithPanel.defaultProps = {
  levels: [],
  value: 0,
};

export default SliderWithPanel;
