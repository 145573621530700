/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, useMemo } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import moment, { Moment } from 'moment';
import { ITextField, TextField } from '../inputs';
import { IDatePicker } from './date-picker.interface';
import { datePickerStyles } from './date-picker.styles';

const isWeekend = (date: Moment) => date.day() === 0;

export const DatePicker: FC<IDatePicker> = ({
  label,
  meta,
  onChange,
  maxDate,
  minDate,
  value,
  format,
  helperText,
  ...restProps
}) => {
  const maxDateMemoize = useMemo(
    () => (maxDate ? moment(maxDate) : undefined),
    [maxDate],
  );
  const minDateMemoize = useMemo(
    () => (minDate ? moment(minDate) : undefined),
    [minDate],
  );

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MuiDatePicker
        {...restProps}
        maxDate={maxDateMemoize}
        minDate={minDateMemoize}
        shouldDisableDate={isWeekend}
        inputFormat={format || 'MM-DD-YYYY'}
        label={label}
        onChange={onChange}
        PaperProps={{ sx: datePickerStyles.root }}
        renderInput={({ error, ...rest }) => (
          <TextField
            {...rest}
            meta={meta as any}
            type="text"
            label={label}
            variant="standard"
            error={error}
            field={rest as ITextField['field']}
            sx={{
              '& .Mui-focused': {
                svg: {
                  color: '#150090',
                },
              },
              svg: {
                color: meta.touched && meta.error ? '#FF6067' : 'inherit',
              },
            }}
            inputProps={{
              ...rest.inputProps,
              readOnly: true,
              placeholder: label,
            }}
          />
        )}
        value={value}
      />
    </LocalizationProvider>
  );
};
