import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from './Button';
import LinkedIn from './LinkedInAuthComponents/LinkedIn';

export const LinkedInButton = ({
  children,
  handleLinkedInSubmit,
  loading,
  ...props
}) => {
  const classes = useStyles();

  const handleResponse = async ({ code }) => {
    await handleLinkedInSubmit(code);
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleFailure = () => {};

  return (
    <LinkedIn
      clientId={process.env.NEXT_PUBLIC_LINKEDIN_CLIENT_ID}
      onSuccess={handleResponse}
      onFailure={handleFailure}
      redirectUri={`${process.env.NEXT_PUBLIC_DEPLOYMENT_URL}/en/linkedin`}
      scope={'r_liteprofile r_emailaddress'}
      renderElement={({ onClick }) => (
        <Button
          {...props}
          onClick={onClick}
          className={classes.linkedin}
          color="secondary"
          loading={loading}
        >
          <FontAwesomeIcon
            icon={faLinkedinIn}
            className={classes.icon}
            size="lg"
          />
          {children}
        </Button>
      )}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  linkedin: {
    backgroundColor: theme.palette.linkedin.main,
  },
  icon: {
    marginRight: theme.spacing(1.5),
  },
}));

LinkedInButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  handleLinkedInSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default LinkedInButton;
