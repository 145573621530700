import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';

export const SlideUpModalTransition = React.forwardRef(function Transition(
  props,
  ref,
) {
  return <Slide direction="up" ref={ref} {...props} timeout={600} />;
});

export const DeepLinksModal = ({
  children,
  onClose,
  open,
  title,
  fixedWidth,
  titleComponent,
  contentContainerClass,
  className,
  ...props
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby={title}
      TransitionComponent={SlideUpModalTransition}
      keepMounted
      classes={{ container: classes.root, paper: classes.paper }}
      style={{ zIndex: 1350 }}
      {...props}
    >
      <DialogTitle id={title} classes={{ root: classes.title }}>
        <Typography variant="h4" component={titleComponent}>
          {title}
        </Typography>
      </DialogTitle>

      <DialogContent
        classes={{ root: classnames(classes.content, contentContainerClass) }}
      >
        {children}
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
  },
  content: {
    paddingBottom: theme.spacing(3),
  },
  iconButton: {
    height: 40,
    width: 40,
  },
  root: {
    [theme.breakpoints.down('lg')]: {
      alignItems: 'flex-end',
    },
  },
  paper: {
    [theme.breakpoints.down('lg')]: {
      margin: 0,
      maxWidth: '100%',
      width: '100%',
      borderTopLeftRadius: '30px',
      borderTopRightRadius: '30px',
      borderBottomLeftRadius: '0px',
      borderBottomRightRadius: '0px',
    },
  },
}));

DeepLinksModal.propTypes = {
  children: PropTypes.element.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  fixedWidth: PropTypes.bool,
  titleComponent: PropTypes.string,
  contentContainerClass: PropTypes.string,
  className: PropTypes.string,
};

DeepLinksModal.defaultProps = {
  fixedWidth: false,
  title: '',
  description: '',
  titleComponent: 'h1',
  contentContainerClass: '',
  className: '',
};

export default DeepLinksModal;
