import React from 'react';
import MuiDropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { faCloudUpload } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useTranslate from 'utils/i18n/useTranslate';

export const Dropzone = ({ onDrop, ...props }) => {
  const classes = useStyles();
  const { t } = useTranslate();

  return (
    <MuiDropzone onDrop={onDrop} {...props}>
      {({ getRootProps, getInputProps }) => (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          {...getRootProps()}
          className={classes.container}
        >
          <input {...getInputProps()} />
          <Box className={classes.imageContainer}>
            <FontAwesomeIcon icon={faCloudUpload} size="3x" color="#757B7F" />
          </Box>
          <Box display="flex">
            <Typography component="span" variant="body2">
              {t('dropzone.dragAndDrop')}
              <Typography
                component="span"
                variant="body2"
                className={classes.click}
              >
                {t('dropzone.click')}
              </Typography>
              {t('dropzone.uploadYourFile')}
            </Typography>
          </Box>
        </Box>
      )}
    </MuiDropzone>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    border: '2px dashed',
    borderColor: theme.palette.darkGrey.main,
    borderRadius: 5,
    padding: theme.spacing(3),
    cursor: 'pointer',
  },
  imageContainer: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    borderRadius: '50%',
    backgroundColor: theme.palette.grey.main,
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  click: {
    textDecoration: 'underline',
  },
}));

Dropzone.propTypes = {
  onDrop: PropTypes.func.isRequired,
};

export default Dropzone;
