import { makeStyles } from '@mui/styles';

export const useModalStyles = makeStyles((theme: any) => ({
  modal: {
    width: '100%',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      minWidth: 440,
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
  },
  fixedWidth: {
    [theme.breakpoints.up('sm')]: {
      width: 440,
    },
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  content: {
    paddingBottom: theme.spacing(3),
  },
  iconButton: {
    height: 40,
    width: 40,
  },
}));
