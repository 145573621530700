import React from 'react';
import PropTypes from 'prop-types';
import { Box, Chip } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const SimpleChip = ({
  label,
  ml,
  mt,
  mr,
  mb,
  labelClassName,
  ...props
}) => {
  const classes = useStyles();

  return (
    <Box ml={ml} mt={mt} mr={mr} mb={mb}>
      <Chip
        classes={{
          label: labelClassName || classes.label,
          root: classes.root,
          colorPrimary: classes.colorPrimary,
          colorSecondary: classes.colorSecondary,
        }}
        label={label}
        variant="outlined"
        {...props}
      />
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 28,
    borderColor: theme.palette.grey.main,
    color: theme.palette.darkerGrey.main,
    height: 'auto',
    minHeight: '32px',
  },
  label: {
    fontSize: '0.75rem',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2.5),
    whiteSpace: 'normal',
  },
  colorPrimary: {
    color: theme.palette.success.main,
  },
  colorSecondary: {
    color: theme.palette.black.main,
  },
}));

SimpleChip.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  ml: PropTypes.number,
  mt: PropTypes.number,
  mr: PropTypes.number,
  mb: PropTypes.number,
  labelClassName: PropTypes.string,
};

SimpleChip.defaultProps = {
  ml: 0,
  mt: 0,
  mr: 0,
  mb: 0,
  labelClassName: null,
};

export default SimpleChip;
