/* eslint-disable react/jsx-props-no-spreading */
import React, { ComponentProps, FC } from 'react';
import { Grow, Snackbar as MuiSnackbar } from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { ISnackbar } from './snackbar.interface';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

const GrowTransition: FC<ComponentProps<typeof Grow>> = ({
  children,
  ...props
}) => (
  <Grow {...props} timeout={200}>
    {children}
  </Grow>
);

export const Snackbar: FC<ISnackbar> = ({
  open,
  handleClose,
  message,
  severity,
}) => (
  <MuiSnackbar
    open={open}
    autoHideDuration={6000}
    onClose={handleClose}
    TransitionComponent={GrowTransition}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
  >
    <div>
      <Alert
        onClose={handleClose}
        severity={severity}
        sx={{
          color: 'white',
        }}
      >
        {message}
      </Alert>
    </div>
  </MuiSnackbar>
);

export default Snackbar;
