import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Select as MuiSelect, InputLabel, useTheme } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputBase from '@mui/material/InputBase';
import MenuItem from '@mui/material/MenuItem';
import { makeStyles, withStyles } from '@mui/styles';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

const Input = withStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  root: {
    height: 48,
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    border: '1px solid',
    borderColor: theme.palette.grey.main,
    borderRadius: 8,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    fontSize: '1rem',
    padding: '14px 0 14px 18px',
    '&:focus': {
      backgroundColor: theme.palette.background.paper,
      borderRadius: 8,
      borderColor: theme.palette.darkGrey.main,
    },
  },
}))(InputBase);

export const SelectField = ({
  handleChange,
  value,
  placeholder,
  items = [],
  meta: { touched, error },
  label,
  variant,
  ...props
}) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <FormControl
      className={classnames(classes.formControl)}
      error={touched && !!error}
    >
      {label && (
        <InputLabel
          htmlFor="select"
          classes={{ root: classes.rootLabel, focused: classes.focused }}
        >
          {label}
        </InputLabel>
      )}
      <MuiSelect
        {...props}
        id="select"
        variant={variant}
        value={value}
        onChange={handleChange}
        IconComponent={(propsData) => (
          <Box mr={1} {...propsData}>
            <FontAwesomeIcon
              icon={faChevronDown}
              color={
                touched && error
                  ? theme.palette.error.main
                  : theme.palette.darkerGrey.main
              }
              size="lg"
            />
          </Box>
        )}
        className={classes.select}
        input={
          variant === 'outlined' ? (
            <Input classes={{ input: touched && error ? classes.error : '' }} />
          ) : null
        }
        MenuProps={{ style: { zIndex: 1400 } }}
      >
        {placeholder ? (
          <MenuItem value="" disabled>
            {placeholder}
          </MenuItem>
        ) : null}

        {(items || []).map((item) => (
          <MenuItem value={item.name} key={item.id}>
            {item.name}
          </MenuItem>
        ))}
      </MuiSelect>
      {touched && error && (
        <FormHelperText className={classes.helperText}>{error}</FormHelperText>
      )}
    </FormControl>
  );
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    position: 'relative',
  },
  error: {
    borderColor: theme.palette.error.main,
  },
  helperText: {
    marginLeft: 0,
  },
  root: {
    height: 48,
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    border: '1px solid',
    borderColor: 'red',
    borderRadius: 8,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    fontSize: '1rem',
    padding: '14px 0 14px 18px',
    '&:focus': {
      backgroundColor: theme.palette.background.paper,
      borderRadius: 8,
      borderColor: theme.palette.darkGrey.main,
    },
  },
  select: {
    fontSize: '1rem',
    fontWeight: 600,
    '&:before': {
      borderBottomColor: theme.palette.darkGrey.main,
    },
    '&:after': {
      borderBottomColor: theme.palette.secondary.main,
    },
  },
  rootLabel: {
    fontSize: '1rem',
    fontWeight: 600,
    color: theme.palette.darkGrey.main,
    marginBottom: theme.spacing(3),
    marginLeft: '-15px',
  },
  focused: {
    '&$focused': {
      color: theme.palette.secondary.main,
    },
  },
}));

SelectField.defaultProps = {
  placeholder: '',
  items: [],
  value: '',
  label: '',
  variant: 'standard',
  meta: {
    error: '',
    touched: false,
  },
};

SelectField.propTypes = {
  placeholder: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired,
      ]),
      name: PropTypes.string.isRequired,
    }).isRequired,
  ),
  value: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  variant: PropTypes.string,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }),
};

export default SelectField;
