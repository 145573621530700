import React from 'react';
import PropTypes from 'prop-types';
import { Box, Slider, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const EXPERIENCE_VALUES = {
  min: 0,
  max: 15,
};

export const ExperienceSlider = ({ values, onChange }) => {
  const classes = useStyles();

  const handleChange = (_, value) => {
    onChange(value);
  };

  return (
    <Box width="100%">
      <Slider
        classes={{
          root: classes.root,
          track: classes.track,
          rail: classes.rail,
          thumb: classes.thumb,
          valueLabel: classes.valueLabel,
          active: classes.active,
        }}
        step={1}
        min={EXPERIENCE_VALUES.min}
        max={EXPERIENCE_VALUES.max}
        value={values}
        onChange={handleChange}
        valueLabelDisplay="off"
      />

      <Box width="100%" display="flex" justifyContent="space-between">
        <Typography variant="body2" color="textSecondary">
          {values[0]}
        </Typography>

        <Typography variant="body2" color="textSecondary">
          {values[1]}
        </Typography>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'flex-start',
    width: 'calc(100% - 8px)',
    // Track offset for slider no to move outside of track
    '&::after': {
      content: "''",
      backgroundColor: theme.palette.grey.main,
      display: 'block',
      width: 8,
      height: 2,
      position: 'absolute',
      right: -8,
      borderTopRightRadius: 2,
      borderBottomRightRadius: 2,
      opacity: 1,
    },
  },
  active: {},
  track: {
    height: 2,
    color: theme.palette.secondary.main,
    borderRadius: 0,
  },
  rail: {
    color: theme.palette.grey.main,
    opacity: 1,
    height: 2,
    borderRadius: 0,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 12,
    marginTop: -12,
    zIndex: 10,
    boxShadow: '#ebebeb 0 2px 2px',
    '&:focus, &:hover, &$active': {
      boxShadow: '#ccc 0 2px 3px 1px',
    },
  },
}));

ExperienceSlider.propTypes = {
  values: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func.isRequired,
};

ExperienceSlider.defaultProps = {
  values: [0, 0],
};

export default ExperienceSlider;
