import { makeStyles } from '@mui/styles';

const useSliderLabelStyles = makeStyles((theme: any) => ({
  tooltipTextContainer: {
    width: 'max-content',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: 'transparent',
    color: '#000',
  },
  primaryText: {
    fontSize: 12,
    color: theme.palette.common.white,
  },
  secondaryText: {
    fontSize: 12,
    color: theme.palette.common.gray,
  },
}));

export default useSliderLabelStyles;
