import { useGoogleLogin } from '@react-oauth/google';
import React from 'react';
import Image from 'next/image';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import googleIcon from '../../assets/icons/google.svg';
import Button from './Button';

export const GoogleButton = ({
  children,
  handleGoogleSubmit,
  loading,
  ...props
}) => {
  const classes = useStyles();

  const handleResponse = async (res) => {
    if (!res.error) {
      await handleGoogleSubmit(res.access_token);
    }
  };

  const onClick = useGoogleLogin({
    onSuccess: handleResponse,
    onError: handleResponse,
  });

  return (
    <Button
      {...props}
      className={classes.google}
      color="inherit"
      onClick={onClick}
      loading={loading}
    >
      <Image
        src={googleIcon}
        alt="Google logo icon"
        title="Google logo icon"
        className={classes.icon}
        width={24}
        height={24}
      />

      {children}
    </Button>
  );
};

const useStyles = makeStyles((theme) => ({
  google: {
    borderStyle: 'solid',
    borderWidth: 1.5,
    borderColor: theme.palette.darkGrey.main,
    backgroundColor: theme.palette.google.main,
  },
  icon: {
    marginRight: theme.spacing(1.5),
    position: 'relative',
  },
}));

GoogleButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  handleGoogleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default GoogleButton;
